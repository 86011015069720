
    var riot = require('riot')
    
riot.tag2('bruntsmap', '<gmap-markers markers="{markers1}" linkedmap="{opts.linkedmap}"></gmap-markers>', '', '', function(opts) {
this.markers1 = [
  {
    name: "NG18 2AT",
    latitude: 53.151990,
    longitude: -1.189900
  }
];
});
    
  